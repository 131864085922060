:root {
    --color-primary: #2584ff;
    --color-secondary: #00d9ff;
    --color-accent: #ff3400;
    --color-headings: #1b0760;
    --color-body: #918ca4;
    --color-body-darker: #5c5577;
    --color-border: #ccc;
    --border-radius: 30px;
  }
  
  *,
  *::after,
  *::before {
    box-sizing: border-box;
  }
  
  /* Typography */
  ::selection {
    background: var(--color-primary);
    color: #fff;
  }
  
  html {
    font-size: 62.5%;
    scroll-behavior: smooth;
  }
  
  img {
    width: 100%;
  }
  
  body {
    font-family: Inter, Arial, Helvetica, sans-serif;
    font-size: 2rem;
    line-height: 1.5;
    color: var(--color-body);
  }
  
  h1,
  h2,
  h3 {
    color: var(--color-headings);
    margin-bottom: 1rem;
    line-height: 1.1;
  }
  
  h1 {
    font-size: 5.3rem;
  }
  
  h2 {
    font-size: 4rem;
  }
  
  h3 {
    font-size: 2.8rem;
    font-weight: 600;
    line-height: 1.3;
  }
  
  p {
    margin-top: 0;
  }
  
  @media screen and (min-width: 1024px) {
    body {
      font-size: 1.8rem;
    }
  
    h1 {
      font-size: 8rem;
    }
  
    h2 {
      font-size: 4rem;
    }
  
    h3 {
      font-size: 2.4rem;
    }
  }
  
  /* Links */
  a {
    text-decoration: none;
  }
  
  .link-arrow {
    color: var(--color-accent);
    text-transform: uppercase;
    font-size: 2rem;
    font-weight: bold;
  }
  
  .link-arrow::after {
    content: "-->";
    margin-left: 5px;
    transition: margin 0.15s;
  }
  
  .link-arrow:hover::after {
    margin-left: 10px;
  }
  
  @media screen and (min-width: 1024px) {
    .link-arrow {
      font-size: 1.5rem;
    }
  }
  
  /* Badges */
  .badge {
    border-radius: 20px;
    font-size: 2rem;
    font-weight: 600;
    padding: 0.5rem 2rem;
    white-space: nowrap;
  }
  
  .badge--primary {
    background: var(--color-primary);
    color: #fff;
  }
  
  .badge--secondary {
    background: var(--color-secondary);
    color: #fff;
  }
  
  .badge--small {
    font-size: 1.6rem;
    padding: 0.5rem 1.5rem;
  }
  
  @media screen and (min-width: 1024px) {
    .badge {
      font-size: 1.5rem;
    }
  
    .badge--small {
      font-size: 1.2rem;
    }
  }
  
  /* Lists */
  .list {
    list-style: none;
    padding-left: 0;
  }
  
  .list--inline .list__item {
    display: inline-block;
    margin-right: 2rem;
  }
  
  .list--tick {
    list-style-image: url(../images/tick.svg);
    padding-left: 3rem;
    color: var(--color-headings);
  }
  
  .list--tick .list__item {
    padding-left: 0.5rem;
    margin-bottom: 1rem;
  }
  
  @media screen and (min-width: 1024px) {
    .list--tick .list__item {
      padding-left: 0;
    }
  }
  
  /* Icons */
  .icon {
    width: 40px;
    height: 40px;
  }
  
  .icon--small {
    width: 30px;
    height: 30px;
  }
  
  .icon--primary {
    fill: var(--color-primary);
  }
  
  .icon--white {
    fill: #fff;
  }
  
  .icon-container {
    background: #f3f9fa;
    width: 64px;
    height: 64px;
    border-radius: 100%;
    display: inline-flex;
    justify-content: center;
    align-items: center;
  }
  
  .icon-container--accent {
    background: var(--color-accent);
  }
  
  /* Buttons */
  .btn {
    border-radius: 40px;
    border: 0;
    cursor: pointer;
    font-size: 1.8rem;
    font-weight: 600;
    margin: 1rem 0;
    outline: 0;
    padding: 2rem 4vw;
    text-align: center;
    text-transform: uppercase;
    white-space: nowrap;
  }
  
  .btn .icon {
    width: 2rem;
    height: 2rem;
    margin-right: 1rem;
    vertical-align: middle;
  }
  
  .btn--primary {
    background: var(--color-primary);
    color: #fff;
  }
  
  .btn--primary:hover {
    background: #3a8ffd;
  }
  
  .btn--secondary {
    background: var(--color-secondary);
    color: #fff;
  }
  
  .btn--secondary:hover {
    background: #05cdf0;
  }
  
  .btn--accent {
    background: var(--color-accent);
    color: #fff;
  }
  
  .btn--accent:hover {
    background: #ec3000;
  }
  
  .btn--outline {
    background: #fff;
    color: var(--color-headings);
    border: 2px solid var(--color-headings);
  }
  
  .btn--outline:hover {
    background: var(--color-headings);
    color: #fff;
  }
  
  .btn--stretched {
    padding-left: 6rem;
    padding-right: 6rem;
  }
  
  .btn--block {
    width: 100%;
    display: inline-block;
  }
  
  @media screen and (min-width: 1024px) {
    .btn {
      font-size: 1.5rem;
    }
  }
  
  /* Inputs */
  .input {
    border-radius: var(--border-radius);
    border: 1px solid var(--color-border);
    color: var(--color-headings);
    font-size: 2rem;
    outline: 0;
    padding: 1.5rem 3.5rem;
  }
  
  ::placeholder {
    color: #cdcbd7;
  }
  
  .input-group {
    border: 1px solid var(--color-border);
    border-radius: var(--border-radius);
    display: flex;
  }
  
  .input-group .input {
    border: 0;
    flex-grow: 1;
    padding: 1.5rem 2rem;
    width: 0;
  }
  
  .input-group .btn {
    margin: 4px;
  }
  
  @media screen and (min-width: 1024px) {
    .input {
      font-size: 1.5rem;
    }
  }
  
  /* Cards */
  .card {
    border-radius: 7px;
    box-shadow: 0 0 20px 10px #f3f3f3;
    overflow: hidden;
  }
  
  .card__header,
  .card__body {
    padding: 2rem 7%;
  }
  
  .card--primary .card__header {
    background: var(--color-primary);
    color: #fff;
  }
  
  .card--secondary .card__header {
    background: var(--color-secondary);
    color: #fff;
  }
  
  .card--primary .badge--primary {
    background: #126de4;
  }
  
  .card--secondary .badge--secondary {
    background: #02cdf1;
  }
  
  /* Plans */
  .plan {
    transition: transform 0.2s ease-out;
  }
  
  .plan__name {
    color: #fff;
    margin: 0;
    font-weight: 500;
    font-size: 2.4rem;
  }
  
  .plan__price {
    font-size: 5rem;
  }
  
  .plan__billing-cycle {
    font-size: 2rem;
    font-weight: 300;
    opacity: 0.8;
    margin-right: 1rem;
  }
  
  .plan__description {
    font-size: 2rem;
    font-weight: 300;
    letter-spacing: 1px;
    display: block;
  }
  
  .plan .list__item {
    margin-bottom: 2rem;
  }
  
  .plan--popular .card__header {
    position: relative;
  }
  
  .plan--popular .card__header::before {
    content: url(../images/popular.svg);
    width: 40px;
    display: inline-block;
    position: absolute;
    top: -6px;
    right: 5%;
  }
  
  .plan:hover {
    transform: scale(1.05);
  }
  
  .plan--popular:hover {
    transform: scale(1.15);
  }

  .plan-price-info {
    font-size: 1.5rem;
    text-align: center;
    padding-bottom: 5rem;
    padding-left: 3rem;
    padding-right: 3rem;
  }
  
  @media screen and (min-width: 1024px) {
    .plan--popular {
      transform: scale(1.1);
    }
  
    .plan__name {
      font-size: 1.4rem;
    }
  
    .plan__price {
      font-size: 5rem;
    }
  
    .plan__billing-cycle {
      font-size: 1.6rem;
    }
  
    .plan__description {
      font-size: 1.7rem;
    }

    .plan-price-info {
      padding-top: 1rem;
      padding-bottom: 5rem;
      padding-left: 8rem;
      padding-right: 8rem;

    }
  }
  
  /* Media */
  .media {
    display: flex;
    margin-bottom: 4rem;
  }
  
  .media__title {
    margin-top: 0;
  }
  
  .media__body {
    margin: 0 2rem;
  }
  
  .media__image {
    margin-top: 1rem;
  }

  .media .media__image img {
    width: 50px;
  }

  /* Quotes */
  .quote {
    font-size: 3rem;
    font-style: italic;
    color: var(--color-body-darker);
    line-height: 1.3;
  }
  
  .quote__text::before {
    content: open-quote;
  }
  
  .quote__text::after {
    content: close-quote;
  }
  
  .quote__author {
    font-size: 2rem;
    font-weight: 500;
    font-style: normal;
    margin-bottom: 0;
  }
  
  .quote__organization {
    color: var(--color-headings);
    opacity: 0.4;
    font-size: 1.5rem;
    font-style: normal;
  }
  
  .quote__line {
    position: relative;
    bottom: 10px;
  }
  
  @media screen and (min-width: 1024px) {
    .quote {
      font-size: 2rem;
    }
  
    .quote__author {
      font-size: 2.4rem;
    }
  
    .quote__organization {
      font-size: 1.6rem;
    }
  }
  
  /* Grids */
  .grid {
    display: grid;
  }
  
  @media screen and (min-width: 768px) {
    .grid--1x2 {
      grid-template-columns: repeat(2, 1fr);
    }
  }
  
  @media screen and (min-width: 1024px) {
    .grid--1x3 {
      grid-template-columns: repeat(3, 1fr);
    }
  }
  
  /* Testimonials */
  .testimonial {
    padding: 3rem;
  }
  
  .testimonial .quote {
    margin: 2rem 0;
    font-size: 110%;
  }
  
  .testimonial__image {
    position: relative;
    margin: 0 3rem;
  }
  
  .testimonial__image > .icon-container {
    position: absolute;
    top: 3rem;
    right: -32px;
  }
  
  @media screen and (min-width: 768px) {
    .testimonial__image {
      margin: 0;
    }
  
    .testimonial .quote {
      margin: 5rem 0 0 6rem;
      font-size: 120%;
      line-height: 1.5;
    }
  }
  
  /* Callouts */
  .callout {
    padding: 4rem;
    border-radius: 5px;
  }
  
  .callout--primary {
    background: var(--color-primary);
    color: #fff;
  }
  
  .callout__heading {
    color: #fff;
    margin-top: 0;
    font-size: 3rem;
  }
  
  .callout .btn {
    justify-self: center;
    align-self: center;
  }
  
  .callout__content {
    text-align: center;
  }
  
  @media screen and (min-width: 768px) {
    .callout .grid--1x2 {
      grid-template-columns: 1fr auto;
    }
  
    .callout__content {
      text-align: left;
    }
  
    .callout .btn {
      justify-self: start;
      margin: 0 2rem;
    }
  }
  
  /* Collapsibles */
  .collapsible__header {
    display: flex;
    justify-content: space-between;
  }
  
  .collapsible__heading {
    margin-top: 0;
    font-size: 3rem;
  }
  
  .collapsible__chevron {
    transform: rotate(-90deg);
    transition: transform 0.3s;
  }
  
  .collapsible__content {
    max-height: 0;
    overflow: hidden;
    opacity: 0;
    transition: all 0.3s;
  }
  
  .collapsible--expanded .collapsible__chevron {
    transform: rotate(0);
  }
  
  .collapsible--expanded .collapsible__content {
    max-height: 100vh;
    opacity: 1;
  }
  
  /* Blocks */
  .block {
    --padding-vertical: 5rem;
    padding: var(--padding-vertical) 2rem;
  }
  
  .block__header {
    text-align: center;
    margin-bottom: 4rem;
  }
  
  .block__heading {
    margin-top: 0;
  }
  
  .block--dark {
    background: #000;
    color: #7b858b;
  }
  
  .block--dark h1,
  .block--dark h2,
  .block--dark h3 {
    color: #fff;
  }
  
  .block--skewed-right {
    padding-bottom: calc(var(--padding-vertical) + 4rem);
    clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 0% 95%);
  }
  
  .block--skewed-left {
    padding-bottom: calc(var(--padding-vertical) + 4rem);
    clip-path: polygon(0% 0%, 100% 0%, 100% 95%, 0% 100%);
  }
  
  .container {
    max-width: 1140px;
    margin: 0 auto;
  }
  
  /* Navigation Bar */
  .nav {
    background: #000;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    padding: 1rem;
    align-items: center;
  }
  
  .nav__list {
    width: 100%;
    margin: 0;
  }
  
  .nav__item {
    padding: 0.5rem 2rem;
    border-bottom: 1px solid #222;
  }
  
  .nav__item:last-of-type {
    border-bottom: none;
  }
  
  .nav__item > a {
    color: #d2d0db;
    transition: color 0.3s;
  }
  
  .nav__item > a:hover {
    color: #fff;
  }
  
  .nav__toggler {
    opacity: 0.5;
    transition: box-shadow 0.15s;
    cursor: pointer;
  }
  
  .nav.collapsible--expanded .nav__toggler {
    opacity: 1;
    box-shadow: 0 0 0 3px #666;
    border-radius: 5px;
  }
  
  .nav__brand {
    transform: translateY(5px);
    width: 150px;
  }
  
  @media screen and (min-width: 768px) {
    .nav__toggler {
      display: none;
    }
  
    .nav__list {
      width: auto;
      display: flex;
      font-size: 1.6rem;
      max-height: 100%;
      opacity: 1;
    }
  
    .nav__item {
      border: 0;
    }
  }
  
  /* Hero */
  
  .hero__tagline {
    font-size: 2rem;
    color: #b9c3cf;
    letter-spacing: 1px;
    margin: 2rem 0 5rem;
  }
  
  @media screen and (min-width: 768px) {
    .hero__content {
      text-align: left;
      align-self: center;
    }
  }
  
  @media screen and (min-width: 1024px) {
    .hero {
      padding-top: 0;
    }
  }
  
  /* Domain Block */
  .block-domain .input-group {
    box-shadow: 0 0 30px 20px #e6ebee;
    border: 0;
    margin: 4rem auto;
    max-width: 670px;
  }
  
  .block-domain__prices {
    color: var(--color-headings);
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 6rem);
    font-size: 2rem;
    font-weight: 600;
    justify-items: center;
    max-width: 700px;
    margin: 0 auto;
  }
  
  @media screen and (min-width: 768px) {
    .block-domain__prices {
      grid-template-columns: repeat(auto-fit, minmax(10rem, 1fr));
    }
  }
  
  /* Plans Block */
  .block-plans .grid {
    gap: 8rem 4rem;
  }
  
  .block-plans .card {
    max-width: 500px;
    margin: 0 auto 5.5rem auto;
  }
  
  /* Features */
  .feature {
    gap: 4rem 2rem;
    margin: 12rem 0;
  }
  
  .feature:first-of-type {
    margin-top: 6rem;
  }
  
  .feature__heading {
    margin: 1rem 0;
  }
  
  @media screen and (min-width: 768px) {
    .feature:nth-of-type(even) .feature__content {
      order: 2;
    }
  }
  
  /* Showcase Block */
  @media screen and (min-width: 768px) {
    .block-showcase .grid {
      grid-template-columns: 50% 50%;
    }
  
    .block-showcase__image {
      justify-self: end;
    }
  
    .block-showcase__image > img {
      width: auto;
      max-width: 700px;
      padding-right: 3rem;
      padding-top: 3rem;
    }
  }
  
  /* Footer */
  .footer {
    background: #232323;
    padding-top: 10rem;
  }
  
  .footer a {
    color: #777;
    transition: color 0.3s;
  }
  
  .footer a:hover {
    color: #fff;
  }
  
  .footer__section {
    padding: 2rem;
    border-bottom: 1px solid #393939;
  }
  
  .footer__section .list {
    margin: 0;
  }
  
  .footer__heading {
    text-transform: uppercase;
    font-weight: 600;
    font-size: 2.5rem;
    line-height: 1.5;
  }
  
  .footer__brand {
    margin-top: 5rem;
    text-align: center;
  }
  
  .footer__brand img {
    max-width: 230px;
  }
  
  .footer__copyright {
    font-size: 2.1rem;
    color: #fff;
    opacity: 0.3;
  }

  .footer__copyright a:hover, 
  .footer__copyright a:visited,
  .footer__copyright a:link,
  .footer__copyright a:active  {
    color: #fff;
  }

  .footer-p {
    margin-bottom: 0;
  }

  
  @media screen and (min-width: 768px) {
    .footer {
      font-size: 1.5rem;
    }
    .footer__sections {
      grid-template-columns: repeat(auto-fit, minmax(10rem, 1fr));
    }
  
    .footer .collapsible__chevron {
      display: none;
    }
  
    .footer .collapsible__content {
      opacity: 1;
      max-height: 100%;
    }
  
    .footer__brand {
      order: -1;
      margin-top: 1rem;
    }
  
    .footer__copyright {
      font-size: 1.5rem;
    }
  
    .footer__section {
      border: 0;
    }
  
    .footer__heading {
      font-size: 1.6rem;
    }
  }
  
  /* Sign-up Callout */
  .callout-signup {
    transform: translateY(5rem);
  }

